.App {
  text-align: center;
}

.App__header {
  font-size: 2em;
  font-family: 'Coiny', cursive;
  display:flex;
  justify-content: space-around;
}

.App__main {
  display: flex;
}


body {
  background-color: #F1ECE4;
}

.App__left {
  width: 50vw;
  height: 88vh;
}

.App__right {
  width: 50vw;
  height: 88vh;
  border-radius: 10px;
  background-color: #7787b2;
  box-shadow: 0 0 13px -3px #000;
  overflow: scroll;
}

#ace-editor{
  height: 25vh !important;
  width: 45vw !important;
}

@media screen and (max-width: 1100px) {
  .App__main {
    display: flex;
    flex-direction: column;
  }
  .App__left {
    width: 100vw;
    height: inherit;
  }

  .App__right {
    width: 100vw;
    height: 90vh;
  }

  #ace-editor{
    width: 65vw !important;
  }
}

.ace__editor__wrapper{
  display: flex;
  justify-content: center;
}


.left__level__text {
  text-align: left;
  padding-left: 2em;
  margin-right: 2em;
  font-family: 'PT Sans', sans-serif;
  font-size: 1.1em;
}

i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 0.3em;
  width: 0.3em;
  margin-top: 0.5em;
  box-shadow: 0 0 8px -3px #000;
}

.arrow__left {
  transform: rotate(135deg);
  cursor: pointer;
}

.arrow__right {
  transform: rotate(-45deg);
  cursor: pointer;
}

.level__select {
  display: flex;
  font-size: 0.8em;
  font-family: 'Coiny', cursive;
}

.select__dropdown{
  margin-top: 0.1em;
  height: 1.5em;
  box-shadow: 0 0 8px -3px #000;
  cursor: pointer;
}

.level__drop__box {
  position: absolute;
  z-index: 2;
  transform: translateX(-3.873em);
  box-shadow: 0 0 13px -3px #000;
  height: 10em;
  width: 12em;
  background-color: #7787b2;
  display: flex;
  flex-direction: column;
}

.drop__box__levels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.clear__button {
  color: white;
  padding-top: 0.5em;
  font-family: 'PT Sans', sans-serif;
  cursor: pointer;
  font-size: 16px;
}

.level__option {
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 0 13px -3px #000;
  padding-left: 0.4em;
  width: 11.2em;
  height: 1.2em;
  font-size: 0.5em;
  border-radius: 2px;
  text-align: left;
  cursor: pointer;
  margin: 2px;
  line-height: 1.4em;
  background-color: #b1b8cb;
  border: 1px solid transparent;
}

.level__complete {
  background-color: #F1ECE4;
}

.current__level__option{
  border: 1px solid whitesmoke;
}

.level__option:hover {
  border: 1px solid whitesmoke;
}

.arrow-up__container {
  display: flex;
  justify-content: center;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #7787b2;
}

.run__button {
  background-color: whitesmoke;
  width: 5em;
  box-shadow: 0 0 13px -3px #000;
  cursor: pointer;
  font-family: 'Coiny', cursive;
  line-height: 1em;
  height: 4em;
  font-size: 1em;
}

button:focus {outline:0;}

.button__group__wrapper {
  display: flex;
  justify-content: center;
}

.button__group {
  display:flex;
  justify-content: space-between;
  margin-left:3em;
  margin-right:3em;
  padding-bottom: 2em;
}

code {
  background-color: whitesmoke;
  border-radius: 5px;
  border: solid grey 1px;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

@keyframes correct1 {
  from {box-shadow: 0}
  to {box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 78px rgba(63, 195, 128, 1)}
}

@keyframes correct2 {
  from {box-shadow: 0}
  to {box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 78px rgba(63, 195, 128, 1)}
}

@keyframes incorrect1 {
  from {box-shadow: 0}
  to {box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 78px rgba(240, 52, 52, 1)}
}

@keyframes incorrect2 {
  from {box-shadow: 0}
  to {box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 78px rgba(240, 52, 52, 1)}
}

.octocat {
  height: 2.5em;
  cursor: pointer;
  padding-right: 2em;
  padding-left: 2em;
}

.twitter__logo{
  height: 3em;
  cursor: pointer;
  padding-right: 2em;
  padding-left: 2em;
}

.right__footer {
  display: flex;
  justify-content: center;
  padding-top: 1em;
}


.cookie__image{
  width: 27px !important;margin-bottom: 1px !important;box-shadow: none !important;border: none !important;vertical-align: middle !important;
}


.bmc-button{
  margin-left: 1em ;
  margin-top: 0.2em;
  line-height: 36px !important;height:37px !important;text-decoration: none !important;display:inline-flex !important;color:#ffffff !important;background-color:#000000 !important;border-radius: 3px !important;border: 1px solid transparent !important;padding: 1px 9px !important;font-size: 22px !important;letter-spacing: 0.6px !important;box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;font-family:'Cookie', cursive !important;-webkit-box-sizing: border-box !important;box-sizing: border-box !important;-o-transition: 0.3s all linear !important;-webkit-transition: 0.3s all linear !important;-moz-transition: 0.3s all linear !important;-ms-transition: 0.3s all linear !important;transition: 0.3s all linear !important;
}

.bmc-button:hover, .bmc-button:active, .bmc-button:focus {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;text-decoration: none !important;box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;opacity: 0.85 !important;color:#ffffff !important;
}

.slide-out1 {
  animation: slide-out1 1s;
  -webkit-animation: slide-out1 1s;
}

.slide-out2 {
  animation: slide-out2 1s;
  -webkit-animation: slide-out2 1s;
}

@keyframes slide-out1 {
  0% { transform: translateX(0%); }
  50% { transform: translateX(120%); }
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-out1 {
  0% { -webkit-transform: translateX(0%); }
  50% { -webkit-transform: translateX(120%); }
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out2 {
  0% { transform: translateX(0%); }
  50% { transform: translateX(120%); }
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-out2 {
  0% { -webkit-transform: translateX(0%); }
  50% { -webkit-transform: translateX(120%); }
  100% { -webkit-transform: translateX(0%); }
}